import * as React from 'react'

function BancoGenialSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='100%'
      height='70%'
      viewBox='0 0 115 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_3293_8517)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M17.8873 0C16.8147 0 15.9336 0.882721 15.9336 1.95733V2.22598C15.9336 3.30059 16.8147 4.18331 17.8873 4.18331H21.5265V7.8677C21.5265 8.9423 22.4076 9.82502 23.4802 9.82502H23.7484C24.821 9.82502 25.702 8.9423 25.702 7.8677V4.22169V1.99571C25.702 0.921098 24.8593 0.0767604 23.825 0.0383814H23.7867H23.7484H21.5265H17.8873V0Z'
          fill='#4AA9FC'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M36.3531 25.7523C38.3451 25.7523 40.4137 25.0231 41.8694 23.6798C41.9843 23.5647 42.1758 23.5263 42.3291 23.5647L45.8151 24.5241C46.1215 24.6009 46.2748 24.9463 46.0832 25.215C44.1296 28.2853 40.0306 30.1275 36.3148 30.1275C30.0323 30.1275 25.2056 25.0231 25.2056 18.9976C25.2056 12.7034 30.2622 7.82928 36.3148 7.82928C41.6779 7.82928 46.2365 11.744 47.1941 16.8867C47.424 18.0381 47.5389 19.1895 47.3474 20.3792C47.3091 20.6095 47.1175 20.763 46.8877 20.763H30.3388C30.2239 20.763 30.1089 20.8782 30.1473 21.0317C30.7985 24.0636 33.3268 25.7523 36.3531 25.7523ZM30.1473 16.81C30.1089 16.9251 30.2239 17.0786 30.3388 17.0786H42.1758C42.3291 17.0786 42.4057 16.9635 42.3674 16.81C41.7545 14.3153 39.5326 12.1661 36.2765 12.1661C33.0586 12.1661 30.7602 14.3153 30.1473 16.81Z'
          fill='white'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M36.3531 25.7523C38.3451 25.7523 40.4137 25.0231 41.8694 23.6798C41.9843 23.5647 42.1758 23.5263 42.3291 23.5647L45.8151 24.5241C46.1215 24.6009 46.2748 24.9463 46.0832 25.215C44.1296 28.2853 40.0306 30.1275 36.3148 30.1275C30.0323 30.1275 25.2056 25.0231 25.2056 18.9976C25.2056 12.7034 30.2622 7.82928 36.3148 7.82928C41.6779 7.82928 46.2365 11.744 47.1941 16.8867C47.424 18.0381 47.5389 19.1895 47.3474 20.3792C47.3091 20.6095 47.1175 20.763 46.8877 20.763H30.3388C30.2239 20.763 30.1089 20.8782 30.1473 21.0317C30.7985 24.0636 33.3268 25.7523 36.3531 25.7523ZM30.1473 16.81C30.1089 16.9251 30.2239 17.0786 30.3388 17.0786H42.1758C42.3291 17.0786 42.4057 16.9635 42.3674 16.81C41.7545 14.3153 39.5326 12.1661 36.2765 12.1661C33.0586 12.1661 30.7602 14.3153 30.1473 16.81Z'
          fill='#0D1D96'
        />
        <path
          d='M70.9467 16.3876V29.283C70.9467 29.5516 70.7167 29.7819 70.4488 29.7819H66.4646C66.1967 29.7819 65.9667 29.5516 65.9667 29.283V17.8077C65.9667 14.5071 63.6299 12.3963 60.9101 12.3963C57.5004 12.3963 55.2788 15.1595 55.2788 18.6136V29.2446C55.2788 29.5133 55.049 29.7436 54.7808 29.7436H50.7968C50.5287 29.7436 50.2988 29.5133 50.2988 29.2446V8.63509C50.2988 8.36647 50.5287 8.13615 50.7968 8.13615H54.7808C55.049 8.13615 55.2788 8.36647 55.2788 8.63509V10.0167C55.2788 10.2086 55.5086 10.2854 55.6236 10.1703C56.5429 9.13402 59.1098 7.79074 61.9825 7.79074C66.6561 7.79074 70.9467 11.0146 70.9467 16.3876Z'
          fill='white'
        />
        <path
          d='M70.9467 16.3876V29.283C70.9467 29.5516 70.7167 29.7819 70.4488 29.7819H66.4646C66.1967 29.7819 65.9667 29.5516 65.9667 29.283V17.8077C65.9667 14.5071 63.6299 12.3963 60.9101 12.3963C57.5004 12.3963 55.2788 15.1595 55.2788 18.6136V29.2446C55.2788 29.5133 55.049 29.7436 54.7808 29.7436H50.7968C50.5287 29.7436 50.2988 29.5133 50.2988 29.2446V8.63509C50.2988 8.36647 50.5287 8.13615 50.7968 8.13615H54.7808C55.049 8.13615 55.2788 8.36647 55.2788 8.63509V10.0167C55.2788 10.2086 55.5086 10.2854 55.6236 10.1703C56.5429 9.13402 59.1098 7.79074 61.9825 7.79074C66.6561 7.79074 70.9467 11.0146 70.9467 16.3876Z'
          fill='#0D1D96'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M101.321 29.7819C101.053 29.7819 100.823 29.5516 100.823 29.283V28.0165C100.823 27.8246 100.593 27.7478 100.478 27.863C98.9461 29.4365 96.7628 30.089 94.043 30.089C88.1815 30.089 83.3164 24.9845 83.3164 18.959C83.3164 12.6649 88.1815 7.79074 94.043 7.79074C96.7628 7.79074 98.9461 8.63509 100.478 10.247C100.593 10.4005 100.823 10.2854 100.823 10.0935V8.63509C100.823 8.36647 101.053 8.13615 101.321 8.13615H105.305C105.574 8.13615 105.804 8.36647 105.804 8.63509V29.2446C105.804 29.5133 105.574 29.7436 105.305 29.7436H101.321V29.7819ZM87.9521 18.959C87.9521 22.5667 90.7868 25.5986 94.541 25.5986C98.1416 25.5986 101.13 22.5667 101.13 18.959C101.13 15.3514 98.1802 12.2811 94.541 12.2811C90.7868 12.2811 87.9521 15.3898 87.9521 18.959Z'
          fill='white'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M101.321 29.7819C101.053 29.7819 100.823 29.5516 100.823 29.283V28.0165C100.823 27.8246 100.593 27.7478 100.478 27.863C98.9461 29.4365 96.7628 30.089 94.043 30.089C88.1815 30.089 83.3164 24.9845 83.3164 18.959C83.3164 12.6649 88.1815 7.79074 94.043 7.79074C96.7628 7.79074 98.9461 8.63509 100.478 10.247C100.593 10.4005 100.823 10.2854 100.823 10.0935V8.63509C100.823 8.36647 101.053 8.13615 101.321 8.13615H105.305C105.574 8.13615 105.804 8.36647 105.804 8.63509V29.2446C105.804 29.5133 105.574 29.7436 105.305 29.7436H101.321V29.7819ZM87.9521 18.959C87.9521 22.5667 90.7868 25.5986 94.541 25.5986C98.1416 25.5986 101.13 22.5667 101.13 18.959C101.13 15.3514 98.1802 12.2811 94.541 12.2811C90.7868 12.2811 87.9521 15.3898 87.9521 18.959Z'
          fill='#0D1D96'
        />
        <path
          d='M79.8715 8.67362C79.8715 8.405 79.6415 8.17468 79.3736 8.17468H75.3515C75.0829 8.17468 74.8535 8.405 74.8535 8.67362V29.2832C74.8535 29.5518 75.0829 29.7821 75.3515 29.7821H79.3351C79.6036 29.7821 79.8336 29.5518 79.8336 29.2832V8.67362H79.8715Z'
          fill='white'
        />
        <path
          d='M79.8715 8.67362C79.8715 8.405 79.6415 8.17468 79.3736 8.17468H75.3515C75.0829 8.17468 74.8535 8.405 74.8535 8.67362V29.2832C74.8535 29.5518 75.0829 29.7821 75.3515 29.7821H79.3351C79.6036 29.7821 79.8336 29.5518 79.8336 29.2832V8.67362H79.8715Z'
          fill='#0D1D96'
        />
        <path
          d='M110.363 29.7821C110.095 29.7821 109.865 29.5518 109.865 29.2832V0.498922C109.865 0.230269 110.095 0 110.363 0H114.347C114.615 0 114.845 0.230269 114.845 0.498922V29.2832C114.845 29.5518 114.615 29.7821 114.347 29.7821H110.363Z'
          fill='white'
        />
        <path
          d='M110.363 29.7821C110.095 29.7821 109.865 29.5518 109.865 29.2832V0.498922C109.865 0.230269 110.095 0 110.363 0H114.347C114.615 0 114.845 0.230269 114.845 0.498922V29.2832C114.845 29.5518 114.615 29.7821 114.347 29.7821H110.363Z'
          fill='#0D1D96'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M15.8594 29.2832C15.7827 29.2448 15.7061 29.2064 15.6295 29.2448C14.2888 29.8205 12.7947 30.1659 11.2241 30.1659C5.0566 30.1659 0.0383041 25.1766 0.0383041 18.9976C0.0383041 12.8186 5.0566 7.82928 11.2241 7.82928C17.3916 7.82928 22.4099 12.8186 22.4099 18.9976C22.4099 21.7609 21.4139 24.2555 19.7667 26.2128C19.6901 26.2896 19.6901 26.4047 19.7667 26.4815C21.4139 28.1318 22.3716 30.281 22.3716 32.6221C22.3716 37.8801 17.3916 42.1785 11.1858 42.1785C4.97999 42.1785 0 37.8801 0 32.6221C0 32.507 0.0766153 32.4303 0.191538 32.4303H4.67353C4.78845 32.4303 4.86506 32.507 4.86506 32.6221C4.86506 34.0038 5.5929 35.2319 6.74211 36.0762C7.89136 36.959 9.46197 37.4963 11.1858 37.4963C12.9097 37.4963 14.4803 36.959 15.6295 36.0762C16.7787 35.1935 17.5066 33.9654 17.5066 32.6221C17.5066 31.3172 16.8937 30.1659 15.8594 29.2832ZM11.2241 25.7139C14.9399 25.7139 17.928 22.7203 17.928 18.9976C17.928 15.2748 14.9399 12.2813 11.2241 12.2813C7.50829 12.2813 4.5203 15.2748 4.5203 18.9976C4.5203 22.682 7.50829 25.7139 11.2241 25.7139Z'
          fill='white'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M15.8594 29.2832C15.7827 29.2448 15.7061 29.2064 15.6295 29.2448C14.2888 29.8205 12.7947 30.1659 11.2241 30.1659C5.0566 30.1659 0.0383041 25.1766 0.0383041 18.9976C0.0383041 12.8186 5.0566 7.82928 11.2241 7.82928C17.3916 7.82928 22.4099 12.8186 22.4099 18.9976C22.4099 21.7609 21.4139 24.2555 19.7667 26.2128C19.6901 26.2896 19.6901 26.4047 19.7667 26.4815C21.4139 28.1318 22.3716 30.281 22.3716 32.6221C22.3716 37.8801 17.3916 42.1785 11.1858 42.1785C4.97999 42.1785 0 37.8801 0 32.6221C0 32.507 0.0766153 32.4303 0.191538 32.4303H4.67353C4.78845 32.4303 4.86506 32.507 4.86506 32.6221C4.86506 34.0038 5.5929 35.2319 6.74211 36.0762C7.89136 36.959 9.46197 37.4963 11.1858 37.4963C12.9097 37.4963 14.4803 36.959 15.6295 36.0762C16.7787 35.1935 17.5066 33.9654 17.5066 32.6221C17.5066 31.3172 16.8937 30.1659 15.8594 29.2832ZM11.2241 25.7139C14.9399 25.7139 17.928 22.7203 17.928 18.9976C17.928 15.2748 14.9399 12.2813 11.2241 12.2813C7.50829 12.2813 4.5203 15.2748 4.5203 18.9976C4.5203 22.682 7.50829 25.7139 11.2241 25.7139Z'
          fill='#0D1D96'
        />
        <path d='M28.2695 42.1787V35.5775H29.2655V42.1787H28.2695Z' fill='white' />
        <path d='M28.2695 42.1787V35.5775H29.2655V42.1787H28.2695Z' fill='#0D1D96' />
        <path
          d='M36.1598 42.1784V38.302C36.1598 37.7264 35.9682 37.2659 35.6234 36.882C35.2787 36.4982 34.819 36.3447 34.2827 36.3447C33.8996 36.3447 33.5549 36.4215 33.2867 36.6134C32.9802 36.7669 32.7504 36.9972 32.5971 37.3042C32.4439 37.6113 32.329 37.9183 32.329 38.302L31.8693 38.0334C31.8693 37.5345 31.9843 37.074 32.2141 36.6901C32.4439 36.3064 32.7504 35.9993 33.1717 35.7691C33.5932 35.5388 34.0145 35.4236 34.5508 35.4236C35.0488 35.4236 35.5085 35.5388 35.8916 35.8074C36.2747 36.0761 36.5811 36.3831 36.811 36.8053C37.0408 37.2274 37.1557 37.6496 37.1557 38.1486V42.2167H36.1598V42.1784ZM31.333 42.1784V35.5772H32.329V42.1784H31.333Z'
          fill='white'
        />
        <path
          d='M36.1598 42.1784V38.302C36.1598 37.7264 35.9682 37.2659 35.6234 36.882C35.2787 36.4982 34.819 36.3447 34.2827 36.3447C33.8996 36.3447 33.5549 36.4215 33.2867 36.6134C32.9802 36.7669 32.7504 36.9972 32.5971 37.3042C32.4439 37.6113 32.329 37.9183 32.329 38.302L31.8693 38.0334C31.8693 37.5345 31.9843 37.074 32.2141 36.6901C32.4439 36.3064 32.7504 35.9993 33.1717 35.7691C33.5932 35.5388 34.0145 35.4236 34.5508 35.4236C35.0488 35.4236 35.5085 35.5388 35.8916 35.8074C36.2747 36.0761 36.5811 36.3831 36.811 36.8053C37.0408 37.2274 37.1557 37.6496 37.1557 38.1486V42.2167H36.1598V42.1784ZM31.333 42.1784V35.5772H32.329V42.1784H31.333Z'
          fill='#0D1D96'
        />
        <path
          d='M41.331 42.1787L38.3047 35.5775H39.4156L41.9822 41.3727H41.3693L43.9359 35.5775H45.0085L41.9439 42.1787H41.331Z'
          fill='white'
        />
        <path
          d='M41.331 42.1787L38.3047 35.5775H39.4156L41.9822 41.3727H41.3693L43.9359 35.5775H45.0085L41.9439 42.1787H41.331Z'
          fill='#0D1D96'
        />
        <path
          d='M49.0707 42.332C48.4195 42.332 47.8065 42.1785 47.2702 41.8715C46.7339 41.5644 46.3126 41.1422 46.0061 40.6433C45.6996 40.106 45.5464 39.5303 45.5464 38.8779C45.5464 38.2255 45.6996 37.6497 46.0061 37.1509C46.3126 36.6135 46.6956 36.2297 47.2319 35.9227C47.73 35.6157 48.3045 35.4622 48.9558 35.4622C49.5687 35.4622 50.0667 35.6157 50.5264 35.8843C50.9861 36.153 51.3692 36.5368 51.6373 37.0357C51.9055 37.4963 52.0587 38.0719 52.0587 38.686C52.0587 38.7628 52.0587 38.8395 52.0587 38.9547C52.0587 39.0314 52.0204 39.1465 52.0204 39.3001H46.3126V38.379H51.4841L51.1393 38.686C51.1393 38.187 51.0627 37.7649 50.8712 37.4195C50.7179 37.0741 50.4498 36.8054 50.1433 36.6136C49.8369 36.4216 49.4538 36.3065 48.9941 36.3065C48.5344 36.3065 48.113 36.4216 47.7299 36.6136C47.3852 36.8054 47.0787 37.1124 46.8872 37.4963C46.6956 37.88 46.5807 38.3022 46.5807 38.8011C46.5807 39.3001 46.6956 39.7606 46.8872 40.1444C47.117 40.5282 47.3852 40.8352 47.7682 41.0271C48.1513 41.2574 48.5727 41.3342 49.0707 41.3342C49.4538 41.3342 49.8369 41.2574 50.1816 41.1422C50.5264 40.9888 50.7945 40.7584 51.0244 40.4898L51.6373 41.1422C51.3308 41.5261 50.9478 41.7947 50.4881 41.9866C50.0667 42.2168 49.607 42.332 49.0707 42.332Z'
          fill='white'
        />
        <path
          d='M49.0707 42.332C48.4195 42.332 47.8065 42.1785 47.2702 41.8715C46.7339 41.5644 46.3126 41.1422 46.0061 40.6433C45.6996 40.106 45.5464 39.5303 45.5464 38.8779C45.5464 38.2255 45.6996 37.6497 46.0061 37.1509C46.3126 36.6135 46.6956 36.2297 47.2319 35.9227C47.73 35.6157 48.3045 35.4622 48.9558 35.4622C49.5687 35.4622 50.0667 35.6157 50.5264 35.8843C50.9861 36.153 51.3692 36.5368 51.6373 37.0357C51.9055 37.4963 52.0587 38.0719 52.0587 38.686C52.0587 38.7628 52.0587 38.8395 52.0587 38.9547C52.0587 39.0314 52.0204 39.1465 52.0204 39.3001H46.3126V38.379H51.4841L51.1393 38.686C51.1393 38.187 51.0627 37.7649 50.8712 37.4195C50.7179 37.0741 50.4498 36.8054 50.1433 36.6136C49.8369 36.4216 49.4538 36.3065 48.9941 36.3065C48.5344 36.3065 48.113 36.4216 47.7299 36.6136C47.3852 36.8054 47.0787 37.1124 46.8872 37.4963C46.6956 37.88 46.5807 38.3022 46.5807 38.8011C46.5807 39.3001 46.6956 39.7606 46.8872 40.1444C47.117 40.5282 47.3852 40.8352 47.7682 41.0271C48.1513 41.2574 48.5727 41.3342 49.0707 41.3342C49.4538 41.3342 49.8369 41.2574 50.1816 41.1422C50.5264 40.9888 50.7945 40.7584 51.0244 40.4898L51.6373 41.1422C51.3308 41.5261 50.9478 41.7947 50.4881 41.9866C50.0667 42.2168 49.607 42.332 49.0707 42.332Z'
          fill='#0D1D96'
        />
        <path
          d='M55.6621 42.332C55.3173 42.332 54.9725 42.2936 54.6277 42.1785C54.283 42.0634 54.0149 41.9482 53.7467 41.7563C53.4785 41.5644 53.2487 41.3725 53.0571 41.1422L53.6701 40.4898C53.9382 40.7968 54.2447 41.0655 54.5511 41.219C54.8959 41.3725 55.279 41.4493 55.6621 41.4493C56.0835 41.4493 56.4282 41.3725 56.6964 41.1806C56.9645 41.0271 57.0794 40.7584 57.0794 40.4898C57.0794 40.2211 57.0028 39.9909 56.8113 39.8374C56.6198 39.6838 56.3899 39.5687 56.1218 39.4536C55.8536 39.3768 55.5472 39.2617 55.2407 39.1849C54.9342 39.1082 54.6278 38.993 54.3596 38.8395C54.0914 38.686 53.8616 38.4941 53.6701 38.2638C53.5168 38.0336 53.402 37.6882 53.402 37.3044C53.402 36.9205 53.4785 36.6136 53.6701 36.3449C53.8616 36.0763 54.1298 35.8459 54.4746 35.6924C54.8193 35.5389 55.2407 35.4622 55.7004 35.4622C56.1984 35.4622 56.6581 35.5389 57.0411 35.7308C57.4626 35.9227 57.769 36.153 57.999 36.5368L57.3859 37.1509C57.1944 36.8822 56.9262 36.6903 56.6581 36.5751C56.3516 36.4216 56.0452 36.3832 55.6621 36.3832C55.2407 36.3832 54.9342 36.46 54.7044 36.6519C54.4746 36.8054 54.3979 37.0357 54.3979 37.266C54.3979 37.5346 54.4746 37.7265 54.6661 37.88C54.8576 38.0336 55.0492 38.1487 55.3556 38.2255C55.6238 38.3022 55.9302 38.4173 56.2367 38.4941C56.5431 38.5709 56.8496 38.686 57.1178 38.8395C57.3859 38.993 57.6155 39.1849 57.8076 39.4152C57.999 39.6455 58.0755 39.9909 58.0755 40.413C58.0755 40.9887 57.8455 41.4876 57.4243 41.833C56.9645 42.1401 56.3899 42.332 55.6621 42.332Z'
          fill='white'
        />
        <path
          d='M55.6621 42.332C55.3173 42.332 54.9725 42.2936 54.6277 42.1785C54.283 42.0634 54.0149 41.9482 53.7467 41.7563C53.4785 41.5644 53.2487 41.3725 53.0571 41.1422L53.6701 40.4898C53.9382 40.7968 54.2447 41.0655 54.5511 41.219C54.8959 41.3725 55.279 41.4493 55.6621 41.4493C56.0835 41.4493 56.4282 41.3725 56.6964 41.1806C56.9645 41.0271 57.0794 40.7584 57.0794 40.4898C57.0794 40.2211 57.0028 39.9909 56.8113 39.8374C56.6198 39.6838 56.3899 39.5687 56.1218 39.4536C55.8536 39.3768 55.5472 39.2617 55.2407 39.1849C54.9342 39.1082 54.6278 38.993 54.3596 38.8395C54.0914 38.686 53.8616 38.4941 53.6701 38.2638C53.5168 38.0336 53.402 37.6882 53.402 37.3044C53.402 36.9205 53.4785 36.6136 53.6701 36.3449C53.8616 36.0763 54.1298 35.8459 54.4746 35.6924C54.8193 35.5389 55.2407 35.4622 55.7004 35.4622C56.1984 35.4622 56.6581 35.5389 57.0411 35.7308C57.4626 35.9227 57.769 36.153 57.999 36.5368L57.3859 37.1509C57.1944 36.8822 56.9262 36.6903 56.6581 36.5751C56.3516 36.4216 56.0452 36.3832 55.6621 36.3832C55.2407 36.3832 54.9342 36.46 54.7044 36.6519C54.4746 36.8054 54.3979 37.0357 54.3979 37.266C54.3979 37.5346 54.4746 37.7265 54.6661 37.88C54.8576 38.0336 55.0492 38.1487 55.3556 38.2255C55.6238 38.3022 55.9302 38.4173 56.2367 38.4941C56.5431 38.5709 56.8496 38.686 57.1178 38.8395C57.3859 38.993 57.6155 39.1849 57.8076 39.4152C57.999 39.6455 58.0755 39.9909 58.0755 40.413C58.0755 40.9887 57.8455 41.4876 57.4243 41.833C56.9645 42.1401 56.3899 42.332 55.6621 42.332Z'
          fill='#0D1D96'
        />
        <path
          d='M62.7474 42.3319C62.3644 42.3319 62.02 42.2551 61.7135 42.1016C61.4071 41.948 61.1771 41.6794 60.9856 41.3724C60.7941 41.0653 60.7171 40.6815 60.7171 40.221V32.8138H61.7135V40.1826C61.7135 40.5664 61.828 40.8734 62.02 41.1037C62.2115 41.2956 62.4794 41.4107 62.8244 41.4107C63.0539 41.4107 63.2453 41.3724 63.3989 41.2956C63.5518 41.2188 63.7439 41.1037 63.8583 40.9502L64.4712 41.5643C64.2418 41.8329 64.0118 42.0248 63.7439 42.1399C63.4374 42.2551 63.1309 42.3319 62.7474 42.3319ZM59.0317 36.4982V35.5771H64.2418V36.4982H59.0317Z'
          fill='white'
        />
        <path
          d='M62.7474 42.3319C62.3644 42.3319 62.02 42.2551 61.7135 42.1016C61.4071 41.948 61.1771 41.6794 60.9856 41.3724C60.7941 41.0653 60.7171 40.6815 60.7171 40.221V32.8138H61.7135V40.1826C61.7135 40.5664 61.828 40.8734 62.02 41.1037C62.2115 41.2956 62.4794 41.4107 62.8244 41.4107C63.0539 41.4107 63.2453 41.3724 63.3989 41.2956C63.5518 41.2188 63.7439 41.1037 63.8583 40.9502L64.4712 41.5643C64.2418 41.8329 64.0118 42.0248 63.7439 42.1399C63.4374 42.2551 63.1309 42.3319 62.7474 42.3319ZM59.0317 36.4982V35.5771H64.2418V36.4982H59.0317Z'
          fill='#0D1D96'
        />
        <path d='M65.9272 42.1787V35.5775H66.9231V42.1787H65.9272Z' fill='white' />
        <path d='M65.9272 42.1787V35.5775H66.9231V42.1787H65.9272Z' fill='#0D1D96' />
        <path
          d='M68.9551 42.1784V35.5772H69.951V42.1784H68.9551ZM73.5137 42.1784V38.1102C73.5137 37.5729 73.3602 37.1507 73.0157 36.8053C72.6707 36.4599 72.2878 36.3064 71.7898 36.3064C71.4448 36.3064 71.1384 36.3832 70.8704 36.5367C70.6025 36.6901 70.3725 36.882 70.2189 37.1507C70.066 37.4193 69.9895 37.7264 69.9895 38.1102L69.5295 37.9183C69.5295 37.4193 69.6445 36.9588 69.8745 36.6134C70.1045 36.2296 70.411 35.9609 70.7554 35.7307C71.1384 35.5388 71.5598 35.4236 72.0578 35.4236C72.5178 35.4236 72.9393 35.5388 73.3222 35.7307C73.7052 35.9226 73.9731 36.2296 74.2031 36.6134C74.4331 36.9972 74.5481 37.4193 74.5481 37.9566V42.1784H73.5137ZM78.0338 42.1784V38.1102C78.0338 37.5729 77.8808 37.1507 77.5358 36.8053C77.1914 36.4599 76.8079 36.3064 76.272 36.3064C75.927 36.3064 75.659 36.3832 75.3525 36.5367C75.084 36.6901 74.8546 36.882 74.7011 37.1507C74.5481 37.4193 74.4711 37.7264 74.4711 38.1102L73.8966 37.9183C73.9352 37.4193 74.0502 36.9588 74.2796 36.6134C74.5096 36.2296 74.8546 35.9609 75.2375 35.7307C75.6205 35.5388 76.042 35.4236 76.5399 35.4236C76.9993 35.4236 77.4208 35.5388 77.8043 35.7307C78.1873 35.9226 78.4938 36.2296 78.6852 36.6134C78.9152 36.9972 79.0297 37.4193 79.0297 37.9566V42.1784H78.0338Z'
          fill='white'
        />
        <path
          d='M68.9551 42.1784V35.5772H69.951V42.1784H68.9551ZM73.5137 42.1784V38.1102C73.5137 37.5729 73.3602 37.1507 73.0157 36.8053C72.6707 36.4599 72.2878 36.3064 71.7898 36.3064C71.4448 36.3064 71.1384 36.3832 70.8704 36.5367C70.6025 36.6901 70.3725 36.882 70.2189 37.1507C70.066 37.4193 69.9895 37.7264 69.9895 38.1102L69.5295 37.9183C69.5295 37.4193 69.6445 36.9588 69.8745 36.6134C70.1045 36.2296 70.411 35.9609 70.7554 35.7307C71.1384 35.5388 71.5598 35.4236 72.0578 35.4236C72.5178 35.4236 72.9393 35.5388 73.3222 35.7307C73.7052 35.9226 73.9731 36.2296 74.2031 36.6134C74.4331 36.9972 74.5481 37.4193 74.5481 37.9566V42.1784H73.5137ZM78.0338 42.1784V38.1102C78.0338 37.5729 77.8808 37.1507 77.5358 36.8053C77.1914 36.4599 76.8079 36.3064 76.272 36.3064C75.927 36.3064 75.659 36.3832 75.3525 36.5367C75.084 36.6901 74.8546 36.882 74.7011 37.1507C74.5481 37.4193 74.4711 37.7264 74.4711 38.1102L73.8966 37.9183C73.9352 37.4193 74.0502 36.9588 74.2796 36.6134C74.5096 36.2296 74.8546 35.9609 75.2375 35.7307C75.6205 35.5388 76.042 35.4236 76.5399 35.4236C76.9993 35.4236 77.4208 35.5388 77.8043 35.7307C78.1873 35.9226 78.4938 36.2296 78.6852 36.6134C78.9152 36.9972 79.0297 37.4193 79.0297 37.9566V42.1784H78.0338Z'
          fill='#0D1D96'
        />
        <path
          d='M84.011 42.332C83.3595 42.332 82.7466 42.1785 82.2107 41.8715C81.6742 41.5644 81.2527 41.1422 80.9462 40.6433C80.6398 40.106 80.4868 39.5303 80.4868 38.8779C80.4868 38.2255 80.6398 37.6497 80.9462 37.1509C81.2527 36.6135 81.6357 36.2297 82.1721 35.9227C82.6701 35.6157 83.2445 35.4622 83.896 35.4622C84.5089 35.4622 85.0069 35.6157 85.4663 35.8843C85.9263 36.153 86.3093 36.5368 86.5772 37.0357C86.8457 37.4963 86.9987 38.0719 86.9987 38.686C86.9987 38.7628 86.9987 38.8395 86.9987 38.9547C86.9987 39.0314 86.9607 39.1465 86.9607 39.3001H81.2527V38.379H86.4243L86.0793 38.686C86.0793 38.187 86.0028 37.7649 85.8113 37.4195C85.6584 37.0741 85.3898 36.8054 85.0834 36.6136C84.7769 36.4216 84.3939 36.3065 83.9345 36.3065C83.4745 36.3065 83.053 36.4216 82.6701 36.6136C82.3251 36.8054 82.0186 37.1124 81.8271 37.4963C81.6357 37.88 81.5207 38.3022 81.5207 38.8011C81.5207 39.3001 81.6357 39.7606 81.8271 40.1444C82.0571 40.5282 82.3251 40.8352 82.7086 41.0271C83.0916 41.2574 83.513 41.3342 84.011 41.3342C84.3939 41.3342 84.7769 41.2574 85.1219 41.1422C85.4663 40.9888 85.7348 40.7584 85.9648 40.4898L86.5772 41.1422C86.2707 41.5261 85.8878 41.7947 85.4284 41.9866C85.0069 42.2168 84.5089 42.332 84.011 42.332Z'
          fill='white'
        />
        <path
          d='M84.011 42.332C83.3595 42.332 82.7466 42.1785 82.2107 41.8715C81.6742 41.5644 81.2527 41.1422 80.9462 40.6433C80.6398 40.106 80.4868 39.5303 80.4868 38.8779C80.4868 38.2255 80.6398 37.6497 80.9462 37.1509C81.2527 36.6135 81.6357 36.2297 82.1721 35.9227C82.6701 35.6157 83.2445 35.4622 83.896 35.4622C84.5089 35.4622 85.0069 35.6157 85.4663 35.8843C85.9263 36.153 86.3093 36.5368 86.5772 37.0357C86.8457 37.4963 86.9987 38.0719 86.9987 38.686C86.9987 38.7628 86.9987 38.8395 86.9987 38.9547C86.9987 39.0314 86.9607 39.1465 86.9607 39.3001H81.2527V38.379H86.4243L86.0793 38.686C86.0793 38.187 86.0028 37.7649 85.8113 37.4195C85.6584 37.0741 85.3898 36.8054 85.0834 36.6136C84.7769 36.4216 84.3939 36.3065 83.9345 36.3065C83.4745 36.3065 83.053 36.4216 82.6701 36.6136C82.3251 36.8054 82.0186 37.1124 81.8271 37.4963C81.6357 37.88 81.5207 38.3022 81.5207 38.8011C81.5207 39.3001 81.6357 39.7606 81.8271 40.1444C82.0571 40.5282 82.3251 40.8352 82.7086 41.0271C83.0916 41.2574 83.513 41.3342 84.011 41.3342C84.3939 41.3342 84.7769 41.2574 85.1219 41.1422C85.4663 40.9888 85.7348 40.7584 85.9648 40.4898L86.5772 41.1422C86.2707 41.5261 85.8878 41.7947 85.4284 41.9866C85.0069 42.2168 84.5089 42.332 84.011 42.332Z'
          fill='#0D1D96'
        />
        <path
          d='M93.3565 42.1784V38.302C93.3565 37.7264 93.165 37.2659 92.82 36.882C92.4756 36.4982 92.0156 36.3447 91.4797 36.3447C91.0961 36.3447 90.7517 36.4215 90.4832 36.6134C90.1767 36.7669 89.9473 36.9972 89.7938 37.3042C89.6408 37.6113 89.5258 37.9183 89.5258 38.302L89.0658 38.0334C89.0658 37.5345 89.1808 37.074 89.4108 36.6901C89.6408 36.3064 89.9473 35.9993 90.3682 35.7691C90.7517 35.5388 91.2111 35.4236 91.7476 35.4236C92.2456 35.4236 92.705 35.5388 93.0885 35.8074C93.4715 36.0377 93.7779 36.3831 94.0079 36.8053C94.2374 37.2274 94.3524 37.6496 94.3524 38.1486V42.2167H93.3565V42.1784ZM88.5679 42.1784V35.5772H89.5638V42.1784H88.5679Z'
          fill='white'
        />
        <path
          d='M93.3565 42.1784V38.302C93.3565 37.7264 93.165 37.2659 92.82 36.882C92.4756 36.4982 92.0156 36.3447 91.4797 36.3447C91.0961 36.3447 90.7517 36.4215 90.4832 36.6134C90.1767 36.7669 89.9473 36.9972 89.7938 37.3042C89.6408 37.6113 89.5258 37.9183 89.5258 38.302L89.0658 38.0334C89.0658 37.5345 89.1808 37.074 89.4108 36.6901C89.6408 36.3064 89.9473 35.9993 90.3682 35.7691C90.7517 35.5388 91.2111 35.4236 91.7476 35.4236C92.2456 35.4236 92.705 35.5388 93.0885 35.8074C93.4715 36.0377 93.7779 36.3831 94.0079 36.8053C94.2374 37.2274 94.3524 37.6496 94.3524 38.1486V42.2167H93.3565V42.1784ZM88.5679 42.1784V35.5772H89.5638V42.1784H88.5679Z'
          fill='#0D1D96'
        />
        <path
          d='M99.3319 42.3319C98.9489 42.3319 98.6045 42.2551 98.298 42.1016C97.9915 41.948 97.7615 41.6794 97.5701 41.3724C97.3786 41.0653 97.3015 40.6815 97.3015 40.221V32.8138H98.298V40.1826C98.298 40.5664 98.4124 40.8734 98.6045 41.1037C98.796 41.2956 99.0639 41.4107 99.4089 41.4107C99.6383 41.4107 99.8298 41.3724 99.9833 41.2956C100.136 41.2188 100.328 41.1037 100.443 40.9502L101.056 41.5643C100.826 41.8329 100.596 42.0248 100.328 42.1399C100.06 42.2551 99.7154 42.3319 99.3319 42.3319ZM95.6162 36.4982V35.5771H100.826V36.4982H95.6162Z'
          fill='white'
        />
        <path
          d='M99.3319 42.3319C98.9489 42.3319 98.6045 42.2551 98.298 42.1016C97.9915 41.948 97.7615 41.6794 97.5701 41.3724C97.3786 41.0653 97.3015 40.6815 97.3015 40.221V32.8138H98.298V40.1826C98.298 40.5664 98.4124 40.8734 98.6045 41.1037C98.796 41.2956 99.0639 41.4107 99.4089 41.4107C99.6383 41.4107 99.8298 41.3724 99.9833 41.2956C100.136 41.2188 100.328 41.1037 100.443 40.9502L101.056 41.5643C100.826 41.8329 100.596 42.0248 100.328 42.1399C100.06 42.2551 99.7154 42.3319 99.3319 42.3319ZM95.6162 36.4982V35.5771H100.826V36.4982H95.6162Z'
          fill='#0D1D96'
        />
        <path
          d='M105.499 42.3318C104.848 42.3318 104.273 42.1784 103.775 41.8713C103.277 41.5643 102.855 41.1421 102.549 40.6048C102.242 40.0675 102.089 39.4918 102.089 38.8394C102.089 38.1869 102.242 37.6113 102.549 37.1123C102.855 36.6134 103.277 36.1912 103.775 35.8842C104.311 35.5772 104.886 35.4236 105.499 35.4236C106.15 35.4236 106.724 35.5772 107.222 35.8842C107.72 36.1912 108.142 36.6134 108.448 37.1123C108.755 37.6113 108.908 38.1869 108.908 38.8394C108.908 39.4918 108.755 40.0675 108.448 40.6048C108.142 41.1037 107.759 41.5259 107.222 41.833C106.724 42.1784 106.15 42.3318 105.499 42.3318ZM105.499 41.3724C105.959 41.3724 106.38 41.2572 106.724 41.0653C107.107 40.8351 107.376 40.5665 107.567 40.1826C107.797 39.7989 107.874 39.3767 107.874 38.8778C107.874 38.4172 107.759 37.9951 107.567 37.6113C107.376 37.2274 107.069 36.9588 106.724 36.7286C106.38 36.4983 105.959 36.4215 105.499 36.4215C105.039 36.4215 104.618 36.5367 104.273 36.7286C103.89 36.9588 103.622 37.2274 103.43 37.6113C103.239 37.9951 103.124 38.4172 103.124 38.8778C103.124 39.3767 103.239 39.7989 103.43 40.1826C103.622 40.5665 103.928 40.8351 104.273 41.0653C104.656 41.2572 105.039 41.3724 105.499 41.3724Z'
          fill='white'
        />
        <path
          d='M105.499 42.3318C104.848 42.3318 104.273 42.1784 103.775 41.8713C103.277 41.5643 102.855 41.1421 102.549 40.6048C102.242 40.0675 102.089 39.4918 102.089 38.8394C102.089 38.1869 102.242 37.6113 102.549 37.1123C102.855 36.6134 103.277 36.1912 103.775 35.8842C104.311 35.5772 104.886 35.4236 105.499 35.4236C106.15 35.4236 106.724 35.5772 107.222 35.8842C107.72 36.1912 108.142 36.6134 108.448 37.1123C108.755 37.6113 108.908 38.1869 108.908 38.8394C108.908 39.4918 108.755 40.0675 108.448 40.6048C108.142 41.1037 107.759 41.5259 107.222 41.833C106.724 42.1784 106.15 42.3318 105.499 42.3318ZM105.499 41.3724C105.959 41.3724 106.38 41.2572 106.724 41.0653C107.107 40.8351 107.376 40.5665 107.567 40.1826C107.797 39.7989 107.874 39.3767 107.874 38.8778C107.874 38.4172 107.759 37.9951 107.567 37.6113C107.376 37.2274 107.069 36.9588 106.724 36.7286C106.38 36.4983 105.959 36.4215 105.499 36.4215C105.039 36.4215 104.618 36.5367 104.273 36.7286C103.89 36.9588 103.622 37.2274 103.43 37.6113C103.239 37.9951 103.124 38.4172 103.124 38.8778C103.124 39.3767 103.239 39.7989 103.43 40.1826C103.622 40.5665 103.928 40.8351 104.273 41.0653C104.656 41.2572 105.039 41.3724 105.499 41.3724Z'
          fill='#0D1D96'
        />
        <path
          d='M112.587 42.332C112.242 42.332 111.898 42.2936 111.553 42.1785C111.208 42.0634 110.94 41.9482 110.672 41.7563C110.403 41.5644 110.174 41.3725 109.982 41.1422L110.595 40.4898C110.863 40.7968 111.17 41.0655 111.476 41.219C111.821 41.3725 112.204 41.4493 112.587 41.4493C113.009 41.4493 113.353 41.3725 113.622 41.1806C113.89 41.0271 114.005 40.7584 114.005 40.4898C114.005 40.2211 113.928 39.9909 113.736 39.8374C113.545 39.6838 113.315 39.5687 113.047 39.4536C112.779 39.3768 112.472 39.2617 112.166 39.1849C111.859 39.1082 111.553 38.993 111.285 38.8395C111.016 38.686 110.787 38.4941 110.595 38.2638C110.442 38.0336 110.327 37.6882 110.327 37.3044C110.327 36.9205 110.403 36.6136 110.595 36.3449C110.787 36.0763 111.055 35.8459 111.399 35.6924C111.744 35.5389 112.166 35.4622 112.625 35.4622C113.123 35.4622 113.583 35.5389 113.966 35.7308C114.388 35.9227 114.694 36.153 114.924 36.5368L114.311 37.1509C114.12 36.8822 113.851 36.6903 113.583 36.5751C113.277 36.4216 112.97 36.3832 112.587 36.3832C112.166 36.3832 111.859 36.46 111.629 36.6519C111.399 36.8054 111.323 37.0357 111.323 37.266C111.323 37.5346 111.399 37.7265 111.591 37.88C111.783 38.0336 111.974 38.1487 112.281 38.2255C112.549 38.3022 112.855 38.4173 113.162 38.4941C113.468 38.5709 113.775 38.686 114.043 38.8395C114.311 38.993 114.541 39.1849 114.733 39.4152C114.924 39.6455 115.001 39.9909 115.001 40.413C115.001 40.9887 114.771 41.4876 114.349 41.833C113.89 42.1401 113.315 42.332 112.587 42.332Z'
          fill='white'
        />
        <path
          d='M112.587 42.332C112.242 42.332 111.898 42.2936 111.553 42.1785C111.208 42.0634 110.94 41.9482 110.672 41.7563C110.403 41.5644 110.174 41.3725 109.982 41.1422L110.595 40.4898C110.863 40.7968 111.17 41.0655 111.476 41.219C111.821 41.3725 112.204 41.4493 112.587 41.4493C113.009 41.4493 113.353 41.3725 113.622 41.1806C113.89 41.0271 114.005 40.7584 114.005 40.4898C114.005 40.2211 113.928 39.9909 113.736 39.8374C113.545 39.6838 113.315 39.5687 113.047 39.4536C112.779 39.3768 112.472 39.2617 112.166 39.1849C111.859 39.1082 111.553 38.993 111.285 38.8395C111.016 38.686 110.787 38.4941 110.595 38.2638C110.442 38.0336 110.327 37.6882 110.327 37.3044C110.327 36.9205 110.403 36.6136 110.595 36.3449C110.787 36.0763 111.055 35.8459 111.399 35.6924C111.744 35.5389 112.166 35.4622 112.625 35.4622C113.123 35.4622 113.583 35.5389 113.966 35.7308C114.388 35.9227 114.694 36.153 114.924 36.5368L114.311 37.1509C114.12 36.8822 113.851 36.6903 113.583 36.5751C113.277 36.4216 112.97 36.3832 112.587 36.3832C112.166 36.3832 111.859 36.46 111.629 36.6519C111.399 36.8054 111.323 37.0357 111.323 37.266C111.323 37.5346 111.399 37.7265 111.591 37.88C111.783 38.0336 111.974 38.1487 112.281 38.2255C112.549 38.3022 112.855 38.4173 113.162 38.4941C113.468 38.5709 113.775 38.686 114.043 38.8395C114.311 38.993 114.541 39.1849 114.733 39.4152C114.924 39.6455 115.001 39.9909 115.001 40.413C115.001 40.9887 114.771 41.4876 114.349 41.833C113.89 42.1401 113.315 42.332 112.587 42.332Z'
          fill='#0D1D96'
        />
      </g>
      <defs>
        <clipPath id='clip0_3293_8517'>
          <rect width='115' height='42.6293' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BancoGenialSVG
