import React from 'react'
import { useMediaQuery } from 'hooks'
import { Box as MaterialBox, Typography } from '@material-ui/core'

import { Box as Container, OnidataLink } from './styles'

export const Box: React.FC = ({ children }) => {
  const isResponsive = useMediaQuery('sm')
  return (
    <MaterialBox width={isResponsive ? '100%' : 'unset'}>
      <Container>{children}</Container>
      <MaterialBox margin='0 auto' mt={3} display={isResponsive ? 'none' : 'block'}>
        <Typography align='center' color='secondary'>
          <small>
            Desenvolvido por:{' '}
            <OnidataLink
              href='https://lp.matera.com.br/solucao-core-banking'
              target='_blank'
              rel='noopener noreferrer'
            >
              Matera
            </OnidataLink>
          </small>
        </Typography>
      </MaterialBox>
    </MaterialBox>
  )
}

export default Box
