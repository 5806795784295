import React from 'react'
import { LoadableComponent } from 'loadable__component'
import { Theme } from '@material-ui/core/styles'
import ILayout from './layout'
import ITheme, { ILogo } from './theme'
import { IFunctions } from './auth'
import { IPermission } from './permission'
import IRoute from './route'
import { TSubModules } from './submodules'
import { ICompany } from './company'

export interface IInformations {
  empresas: ICompany[]
  empresa?: ICompany
  id: string
  cnpj: string
}

export interface IEntity {
  id: string
  name: string
  path: string
  route: { [key: string]: IRoute }
  theme: ITheme
  logo: ILogo
  materialTheme: Theme
  permissions: IPermission[]
  component: LoadableComponent<ILayout> | React.FC<ILayout>
  funcao?: IFunctions
  funcoes?: IFunctions[]
  module?: {
    mainModule: ENTITY_ENUM
    subModule?: TSubModules
  }
  isCompanyPrivate?: boolean
}

export interface IFunctionsEntity {
  id: string
  nome: string
  tipo: ENTITY_ENUM
  informacoes: IInformations
}

export enum ENTITY_ENUM {
  EMPLOYEE = 'emprego',
  COMPANY = 'empresa',
  FINANCIAL = 'financeira',
  SALES_CHANNEL = 'canalvenda',
  CLIENT_CP = 'clientecp',
  CORBAN = 'corban',
  CDC = 'canalvendacdc',
  CLIENT_CDC = 'clientecdc',
  CDCVEICULOS = 'canalvendacdcveiculo',
  CLIENT_CDV = 'clientecdcveiculo',
  PJ = 'canalvendapj',
  CLIENT_PJ = 'clientepj',
  SUPERVISOR = 'supervisor',
  CLIENT_CDV_PJ = 'clientecdcveiculopj',
}

export default IEntity
